import React from 'react'
import { AnexSignUp } from '@anex/signup-component'

import { Layout } from '../layouts/Layout'
import { SEO } from '../components/shared'

const windowGlobal = typeof window !== 'undefined' && window

const SignUpPage = () => (
  <Layout>
    <SEO
      title="Pronto NBN Sign Up"
      description="Connect your world with Broadband that never sleeps!"
    />

    <main id="page-sign-up" className="single">
      <div className="container">
        <AnexSignUp
          wholesaleCustomerCode="PB"
          anexApiBaseUrl={windowGlobal['ANEX_API_ENDPOINT']}
          anexApiToken={windowGlobal['ANEX_API_TOKEN']}
          bPointBaseUrl={windowGlobal['BPOINT_API_ENDPOINT']}
          eWayBaseUrl={windowGlobal['EWAY_API_ENDPOINT']}
          googleApiKey={windowGlobal['GOOGLE_API_KEY']}
          saleFailureAdvice={windowGlobal['SALE_FAILURE_ADVICE']}
          authEndPointUrl={windowGlobal['AUTH_ENDPOINT']}
          googleRecaptchaKey={windowGlobal['GOOGLE_RECAPTCHA_KEY']}
        />
      </div>
    </main>
  </Layout>
)

export default SignUpPage
